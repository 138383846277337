<template>
  <div >
    <CCard class="p-0 m-0 form-card">
      <CCardHeader>
          <label class="header-title">医療機関管理{{isCollapsed ?"(一覧モード)":"(登録モード)"}}</label>
<!--        <CBadge color="success" class="ml-2" v-if="formData.id != null">ID:{{ formData.id }}</CBadge>-->
        <div class="card-header-actions">
          <CButton color="primary" size="sm" @click="isCollapsed=!isCollapsed">{{isCollapsed ? '登録':'一覧'}}モードへ</CButton>
        </div>
      </CCardHeader>
      <CCollapse :show="!isCollapsed" :duration="200">
        <CCardBody>
          <CRow class="mt-0">
            <CCol col="6">
              <CInput class="mt-0" horizontal label="医療機関名称" v-model="formData.name" addLabelClasses="required"/>
            </CCol>
            <CCol col="6">
              <CInput class="mt-0" horizontal label="医療機関名称(カナ)" v-model="formData.nameKana" addLabelClasses="required"/>
            </CCol>
          </CRow>
          <CRow class="mt-0">
            <div class="col-6">
              <div role="group" class="mt-0 form-group form-row">
                <label for="prefectureId" class="col-form-label col-sm-3 required">都道府県</label>
                <div class="col-sm-9">
                  <v-select
                      id="prefectureId"
                      v-model="formData.prefectureId"
                      :options="prefectures"
                      key="prefectureId"
                      label="prefectureName"
                      :reduce="prefectures => prefectures.prefectureId">
                    <template slot="no-options">データがありません。</template>
                  </v-select>
                </div>
              </div>
            </div>

            <div class="col-6">
              <CInput class="mt-0" horizontal label="市区町村" v-model="formData.city"  addLabelClasses="required"/>
            </div>
          </CRow>

          <CRow class="mt-0">
            <CCol col="6">
              <CInput class="mt-0" horizontal label="連絡先担当者名" v-model="formData.contactName" addLabelClasses="required"/>
            </CCol>
            <CCol col="6">
              <CInput class="mt-0" horizontal label="連絡先電話番号" @keypress="isNumber($event)" v-model="formData.contactTel" addLabelClasses="required"/>
            </CCol>
          </CRow>

          <CRow class="mt-0">
            <CCol col="6">
              <CInput class="mt-0" horizontal label="メモ" v-model="formData.contactMemo"/>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <SaveButtons :id="formData.id" @formInit="formInit" @save="save"/>
        </CCardFooter>
      </CCollapse>
    </CCard>
    <CCard class="p-0 m-0 grid-card">
      <CCardBody class="p-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword"></MyBeeSearchBar>
        <BeeGridTable
            ref="bee"
            border
            stripe
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="gridCardHeight"
            highlightRow
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false">
          <!-- HEADER ソート可能にするために 必要-->
          <template slot-scope="{ column,index }" slot="nameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="addressHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="updatedHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <!-- cell -->
          <template slot-scope="{ row,index }" slot="name"><div class="text-left">{{row.name}}</div></template>
          <template slot-scope="{ row,index }" slot="address"><div class="text-left">{{row.address}}</div></template>
          <template slot-scope="{ row,index }" slot="created"><div class="text-left">{{row.created}}</div></template>

          <template slot-scope="{ row,index }" slot="updated"><div class="text-left">{{row.updated}}</div></template>

          <template slot-scope="{ row,index }" slot="after">
              <CButton color="success py-0" @click="update(row)">編集</CButton>
          </template>

          <template slot-scope="{ row,index }" slot="after2">
              <CButton color="info py-0 ml-2" @click="patientRegistManage(row)">患者関連許可</CButton>
          </template>
        </BeeGridTable>
      </CCardBody>
    </CCard>
    <CModal :show.sync="showPatientRegistable"
            size="xl"
            :no-close-on-backdrop="true"
            :centered="true"
            :title="'患者登録許可('+mappingHospitalName+')'">
      <CCard class="mt-1">
        <CCardBody>
          <Agency ref="device" :is-collapsed="isCollapsed" :is-modal="true" :mapping-hospital-id="mappingHospitalId" :is-show="showPatientRegistable"></Agency>
        </CCardBody>
      </CCard>
      <template #footer-wrapper><span></span></template>
    </CModal>

  </div>
</template>

<script>
import SaveButtons from "@/component/parts/SaveButtons";
import Agency from "@/component/Agency";

import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";

export default {
  name: 'HospitalManage',
  components:{
    SaveButtons,Agency,MyBeeHeader, MyBeeSearchBar
  },
  mixins: [BeeMix],
  data: () => {
    return {
      columns: [
        {title: '病院ID', key: 'id',align:"left",width: 60},
        {title: '医療機関', key: 'name',slot:"name", headSlot:"nameHead",align:"center"},
        {title: '住所', key: 'address',slot:"address", headSlot:"addressHead",align:"center"},
        {title: '登録日時', key: 'created',slot:"created",headSlot:"createdHead",align:"center"},
        {title: '更新日時', key: 'updated',slot:"updated",headSlot:"updatedHead",align:"center"},
        {title: ' ', key: 'after',slot:"after",width:80,align:"center"},
        {title: ' ', key: 'after2',slot:"after2",width:150,align:"center"},
      ],
      searchBarPlaceHolder:"医療機関,住所で検索",
      selected: "",
      isCollapsed: true,
      orgTypes: [],
      prefectures: [],
      cities: [],
      formData: {
        id: null,
        name: null,
        nameKana: null,
        //orgTypeCode: null,
        //namePosition: "P",
        prefectureId: null,
        contactName: null,
        contactDept: null,
        contactTel: null,
        contactMemo: null,
        // patientRegiable:null
      },
      showPatientRegistable:false,
      mappingHospitalId:null,
      mappingHospitalName:""
    }
  },
  methods: {
    checkedNamePosition: function (data) {
      console.log("checkedNamePosition:function(data){", data);
    },
    patientRegistManage(row){
      this.mappingHospitalId = row.id;
      this.mappingHospitalName = row.name;
      this.showPatientRegistable = true;
    },
    formInit: function () {
      this.formData = {namePosition: "P"};
    }, save: function () {
        this.axios.post('/api/hospital/save', this.formData, {headers: {'Content-Type': 'application/json'}}).then(response => {
          this.formData.id = response.data.result.id;
            this.goodPaging();
        }).catch(error => console.log(error)
        ).finally(() => this.isLoading = false);
    },
    // changePatientRegiable:function(data){
    //   this.formData.patientRegiable = data;
    // },
    update: function (data) {
      this.axios.get(`/api/hospital/${data.id}`).then(response => {
        this.formData = Object.assign({}, response.data.result);
        this.isCollapsed = false;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    }, goodPaging: function (isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;
      this.axios.post('/api/hospital/searchPaging', this.pagingParams, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    }, getOptions: function () {
      this.axios.post('/api/hospital/getOptions', {}, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.orgTypes = response.data.result.orgTypes;
        this.prefectures = response.data.result.prefectures;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
  },
  mounted() {
    this.getOptions();
  },watch:{
    isCollapsed:function(newValue){
      if(newValue){
        this.formInit();
      }
    }
  }

}
</script>
<style scoped>
label {
  color: #3C4B64;
}
</style>